import React from "react";
import { FiFacebook, FiInstagram } from "react-icons/fi";

const SocialShare = [
  {
    iconName: <FiFacebook />,
    link: "https://www.facebook.com/tucci.giovanni.10",
  },

  {
    iconName: <FiInstagram />,
    link: "https://www.instagram.com/tucci.giovanni/",
  },
];
const Social = () => {
  return (
    <ul>
      {SocialShare.map((val, i) => (
        <li key={i}>
          <a href={val.link} target="_blank" rel="noreferrer">
            {val.iconName}
          </a>
        </li>
      ))}
    </ul>
  );
};

export default Social;
