import React from "react";

const ModalTwo = () => {
  return (
    <div className="popup_informations">
      <div className="image">
        <img src="img/thumbs/4-3.jpg" alt="" />
        <div
          className="main"
          style={{
            backgroundImage: `url(${
              process.env.PUBLIC_URL + "img/service/2.jpg"
            })`,
          }}
        ></div>
      </div>
      {/* End big image */}

      <div className="description">
        <h3>Corsi di formazione in ambito alberghiero & ristorazione</h3>
        <p>
          La formazione aziendale ha lo scopo di mettere in atto una serie di
          attivita' con l'obbiettivo di aumentare le competenze dei
          professionisti impegnati e raggiungere obbiettivi di business.
        </p>
        <p>
          Qual e' il segreto di un buon percorso formativo aziendale? Non ci
          sono trucchi, solo una certezza. Impostare gli obbiettivi corretti e
          mettere al centro il valore delle persone che lavorano all'interno
          dell'organizzazione.
        </p>
        <h3>Quali sono i vantaggi?</h3>
        <div className="in_list">
          <ul>
            <li>
              <p>Stimolare un clima di squadra di collaborazione</p>
            </li>
            <li>
              <p>
                Migliorare la capacita' di risolvere problemi, in autonomia o in
                team
              </p>
            </li>
            <li>
              <p>Aumentare la produttivita' attraverso nuove competenze</p>
            </li>
            <li>
              <p>Migliorare l'organizzazione delle funzioni</p>
            </li>
            <li>
              <p>Aumentare la reattivita' a problematiche esterne</p>
            </li>
            <li>
              <p>Migliorare la gestione dei conflitti</p>
            </li>
          </ul>
        </div>
        <h3>A chi e' rivolto?</h3>
        <div className="in_list">
          <ul>
            <li>
              <p>
                Bisogno individuale: un singolo individuo o un singolo gruppo di
                individui che ha bisogno di migliorare le proprie competenze su
                determinate skills
              </p>
            </li>
            <li>
              <p>
                Bisogno professionale: riferito ad uno specifico ruolo aziendale
              </p>
            </li>
            <li>
              <p>
                Bisogno organizzativo: che influenza ed e' influenzato dagli
                obbiettivi di business
              </p>
            </li>
          </ul>
        </div>
        <h3>
          Gli obbiettivi vengono cosi strutturati sulla base della analisi dei
          fabbisogni, e definiscono le fasi successive, ovvero:
        </h3>
        <div className="in_list">
          <ul>
            <li>
              <p>Progettazione del piano di formazione aziendale</p>
            </li>
            <li>
              <p>
                Erogazione della formazione nelle varie modalita' gia' esistenti
              </p>
            </li>
            <li>
              <p>Monitoraggio dei risultati</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ModalTwo;
