import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Partners = () => {
  var settings = {
    dots: true,
    arrow: true,
    infinite: true,
    speed: 800,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    draggable: true,
    responsive: [
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          arrow: false,
          autoplay: true,
          draggable: true,
          speed: 300,
          dots: true,
        },
      },
    ],
  };

  return (
    <div className="portfolio_inner my_carousel gallery_zoom">
      <ul data-aos="fade-right" data-aos-duration="1200">
        <Slider {...settings}>
          <li>
            <div className="list_inner">
              <div className="image">
                <img
                  src="img/partners/1.jpg"
                  data-tip
                  data-for="product"
                  alt="portfolio"
                />
              </div>
            </div>
          </li>
          {/* End li */}

          <li>
            <div className="list_inner">
              <div className="image">
                <img
                  src="img/partners/2.jpg"
                  data-tip
                  data-for="dribbble"
                  alt="portfolio"
                />
              </div>
            </div>
          </li>
          {/* End li */}

          <li>
            <div className="list_inner">
              <div className="image">
                <img
                  src="img/partners/3.jpg"
                  data-tip
                  data-for="facebook"
                  alt="portfolio"
                />
              </div>
            </div>
          </li>
          {/* End li */}

          <li>
            <div className="list_inner">
              <div className="image">
                <img
                  src="img/partners/4.jpg"
                  data-tip
                  data-for="youtube"
                  alt="portfolio"
                />
              </div>
            </div>
          </li>
          {/* End li */}

          <li>
            <div className="list_inner">
              <div className="image">
                <img
                  src="img/partners/5.jpg"
                  data-tip
                  data-for="web"
                  alt="portfolio"
                />
              </div>
            </div>
          </li>
          {/* End li */}
          <li>
            <div className="list_inner">
              <div className="image">
                <img
                  src="img/partners/6.jpg"
                  data-tip
                  data-for="web"
                  alt="portfolio"
                />
              </div>
            </div>
          </li>
          <li>
            <div className="list_inner">
              <div className="image">
                <img
                  src="img/partners/7.jpg"
                  data-tip
                  data-for="web"
                  alt="portfolio"
                />
              </div>
            </div>
          </li>
          <li>
            <div className="list_inner">
              <div className="image">
                <img
                  src="img/partners/8.jpg"
                  data-tip
                  data-for="web"
                  alt="portfolio"
                />
              </div>
            </div>
          </li>
          <li>
            <div className="list_inner">
              <div className="image">
                <img
                  src="img/partners/9.jpg"
                  data-tip
                  data-for="web"
                  alt="portfolio"
                />
              </div>
            </div>
          </li>
          <li>
            <div className="list_inner">
              <div className="image">
                <img
                  src="img/partners/10.jpg"
                  data-tip
                  data-for="web"
                  alt="portfolio"
                />
              </div>
            </div>
          </li>
          <li>
            <div className="list_inner">
              <div className="image">
                <img
                  src="img/partners/11.jpg"
                  data-tip
                  data-for="web"
                  alt="portfolio"
                />
              </div>
            </div>
          </li>
          <li>
            <div className="list_inner">
              <div className="image">
                <img
                  src="img/partners/12.jpg"
                  data-tip
                  data-for="web"
                  alt="portfolio"
                />
              </div>
            </div>
          </li>
          <li>
            <div className="list_inner">
              <div className="image">
                <img
                  src="img/partners/13.jpg"
                  data-tip
                  data-for="web"
                  alt="portfolio"
                />
              </div>
            </div>
          </li>
          <li>
            <div className="list_inner">
              <div className="image">
                <img
                  src="img/partners/14.jpg"
                  data-tip
                  data-for="web"
                  alt="portfolio"
                />
              </div>
            </div>
          </li>
          <li>
            <div className="list_inner">
              <div className="image">
                <img
                  src="img/partners/15.jpg"
                  data-tip
                  data-for="web"
                  alt="portfolio"
                />
              </div>
            </div>
          </li>
          <li>
            <div className="list_inner">
              <div className="image">
                <img
                  src="img/partners/16.jpg"
                  data-tip
                  data-for="web"
                  alt="portfolio"
                />
              </div>
            </div>
          </li>
          <li>
            <div className="list_inner">
              <div className="image">
                <img
                  src="img/partners/6.jpg"
                  data-tip
                  data-for="web"
                  alt="portfolio"
                />
              </div>
            </div>
          </li>
          {/* End li */}
        </Slider>
      </ul>
    </div>
  );
};

export default Partners;
