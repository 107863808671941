import React from "react";

const experienceContent = [
  {
    year: "2012 - Present",
    position: "Consulente",
    compnayName: "Creative Food Solutions",
    details: `Collaborazioni con diverse aziende al livello internazionale nel food&beverage service`,
  },
  {
    year: "2012 - Present",
    position: "Consulenza per Ricerca&Sviluppo di aziende alimentari nazionali",
    compnayName: "Creative Food Solutions",
    details: `Collaborazioni con diverse aziende al livello internazionale nel food&beverage service`,
  },
  {
    year: "2000 - 2012",
    position: " Executive Chef",
    compnayName: "MaximiliansHotels",
    details: `Responsabile cucina e acquisti per  Hotel Sporting ****, Hotel Ambasciatori ****S`,
  },
  {
    year: "1975 - 2000",
    position: "Ulteriore Esperienza",
    compnayName: "",
    details: `Per piu' dettagli scaricate il CV.`,
  },
];

const Experience = () => {
  const style = {
    width: "50%",
  };
  return (
    <ul>
      {experienceContent.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <img style={style} src="img/about/briefcase.png" alt="icon" />
            <i className="fa fa-briefcase"></i>
          </div>
          <span className="time open-sans-font text-uppercase">{val.year}</span>
          <h5 className="poppins-font text-uppercase">
            {val.position}
            <span className="place open-sans-font">{val.compnayName}</span>
          </h5>
          <p className="open-sans-font">{val.details}</p>
        </li>
      ))}
    </ul>
  );
};

export default Experience;
