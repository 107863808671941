import React from "react";

const ModalOne = () => {
  return (
    <div className="popup_informations">
      <div className="image">
        <img src="img/thumbs/4-3.jpg" alt="" />
        <div
          className="main"
          style={{
            backgroundImage: `url(${
              process.env.PUBLIC_URL + "img/service/1.jpg"
            })`,
          }}
        ></div>
      </div>
      {/* End big image */}

      <div className="description">
        <h3>Consulente Aziendale</h3>
        <p>
          Il lavoro che svolgo per le aziende alimentari si centra sul sapore,
          la messa appunto di nuovi prodotti, l'assaggio e la valutazione di
          quelli esistenti e dei prototipi di nuove realizzazioni, con un
          attenzione alla ricerca di materie prime per soddisfare il palato con sapori autentici.
        </p>
        <h3>Cosa vuole dire consulenza?</h3>
        <div className="in_list">
          <ul>
            <li>
              <p>Ricerca e sviluppo di nuovi prodotti</p>
            </li>
            <li>
              <p>Controllo produzione</p>
            </li>
            <li>
              <p>Scelta materie prime</p>
            </li>
            <li>
              <p>Food Cost</p>
            </li>
          </ul>
        </div>
        {/* End list */}
        <p>Collaborare con me vuol dire anche collaborare con dei professionisti del settore come:  tecnologi, nutrizionisti e chimici.</p>
      </div>
    </div>
  );
};

export default ModalOne;
