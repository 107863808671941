import React from "react";

const Map = () => {
  return (
    <>
      <div className="google-map">
        <iframe
          className="embed-responsive-item"
          title="my location"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2872.1581906979673!2d12.74257405111932!3d43.956092740706794!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x132ce0e5aed7c455%3A0xc8710fa5e1c3aa44!2sVia%20Albert%20Einstein%2C%2013%2C%2047841%20Cattolica%20RN!5e0!3m2!1sen!2sit!4v1637767486717!5m2!1sen!2sit"
        ></iframe>
      </div>
      {/* End google-map */}
    </>
  );
};

export default Map;
