import React from "react";

const ModalThree = () => {
  return (
    <div className="popup_informations">
      <div className="image">
        <img src="img/thumbs/4-3.jpg" alt="" />
        <div
          className="main"
          style={{
            backgroundImage: `url(${
              process.env.PUBLIC_URL + "img/service/3.jpg"
            })`,
          }}
        ></div>
      </div>
      {/* End big image */}

      <div className="description">
        <h3>Inizializzazione Startup</h3>
        <div className="in_list">
          <ul>
            <li>
              <p>Analisi del progetto operativo su location definita</p>
            </li>
            <li>
              <p>Coordinamento delle attivita' di start up</p>
            </li>
            <li>
              <p>Definizione del capitolato di tutti i materiali</p>
            </li>
            <li>
              <p>Standardizzazione delle ricette/offerta</p>
            </li>
            <li>
              <p>Creazione del manuale operativo</p>
            </li>
            <li>
              <p>Affiancamento e selezione del personale</p>
            </li>
            <li>
              <p>Formazione del personale di contatto</p>
            </li>
            <li>
              <p>Affiancamento pre e post apertura</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ModalThree;
