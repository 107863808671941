import React from "react";

const personalInfoContent = [
  { meta: "Nome", metaInfo: "Giovanni " },
  { meta: "Cognome", metaInfo: "Tucci" },
  { meta: "Anni", metaInfo: "62 anni" },
  { meta: "Nazionalita'", metaInfo: "ITA" },
  { meta: "Freelance", metaInfo: "Disponibile" },
  { meta: "Indirizzo", metaInfo: "Cattolica" },
  { meta: "Telefono", metaInfo: "+393483926401" },
  { meta: "Email", metaInfo: "tuccigio59@gmail.com" },
];

const PersonalInfo = () => {
  return (
    <ul className="about-list list-unstyled open-sans-font">
      {personalInfoContent.map((val, i) => (
        <li key={i}>
          <span className="title">{val.meta}: </span>
          <span className="value d-block d-sm-inline-block d-lg-block d-xl-inline-block">
            {val.metaInfo}
          </span>
        </li>
      ))}
    </ul>
  );
};

export default PersonalInfo;
