import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Testimonial() {
  var settings = {
    dots: true,
    arrow: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: false,
    responsive: [
      {
        breakpoint: 575,
        settings: {
          arrow: false,
          slidesToShow: 1,
          speed: 300,
        },
      },
    ],
  };

  const testimonialContent = [
    {
      img: "1",
      desc: "Una collaborazione attiva da diversi anni quella con Giovanni Tucci che ci ha sempre garantito un elevato grado di qualità grazie alle importanti esperienze maturate nel mondo del food and beverage.Un professionista versatile che sa declinare il suo sapere in base ai diversi target di partecipanti. Nei nostri progetti ha tenuto diversi corsi di formazione nell’ambito della cucina quali:“Cucina wellness”, “Formazione di base nel settore della cucina”, “Aiuto Cuoco”, “Esperto in Food and Beverage management”, rivolti a giovani under 30, donne  e migranti da reinserire nel mondo del lavoro, e ai dipendenti di rinomate strutture alberghiere dell’Alto Adige.",
      name: "ForTeam",
      designation: "Knowledge Company Consultancy",
      delayAnimation: "",
    },
    {
      img: "2",
      desc: `Giovanni Tucci è stata la collaborazione, con uno chef, più lunga e duratura che ho avuto nella mia carriera di direttore. Il modo di cucinare di Giovanni ha deliziato costantemente me e tutti i nostri clienti per anni.Con Giovanni siamo cresciuti professionalmente e umanamente ed in questo lungo periodo abbiamo costantemente modificato la ristorazione nel nostro hotel in tutti i servizi, breakfast compreso.Abbiamo riprogettato la cucina, implementato e successivamente aggiornato gli standard di servizio, allineandoli alle mutevoli richieste del mercato. La collaborazione con Giovanni non è stata solo basata sulla modifica dei menu, ma anche su come gestire il servizio, modificandolo per le mutevoli necessità di un hotel che cambia durante l’anno le soluzioni ristorative in base alla differente tipologia di clientela.`,
      name: "Mattia Palazzi",
      designation: "Direttore Hotel Sporting Rimini",
      delayAnimation: "100",
    },
  ];

  return (
    <Slider {...settings}>
      {testimonialContent.map((val, i) => (
        <li
          key={i}
          data-aos="fade-right"
          data-aos-duration="1200"
          data-aos-delay={val.delayAnimation}
        >
          <div className="list_inner">
            <div className="details">
              <div className="author">
                <div className="image">
                  <div
                    className="main"
                    style={{
                      backgroundImage: `url(${
                        process.env.PUBLIC_URL +
                        `img/testimonials/${val.img}.jpg`
                      })`,
                    }}
                  ></div>
                </div>
                {/* End image */}

                <div className="short">
                  <h3>{val.name}</h3>
                  <span>{val.designation}</span>
                </div>
                {/* End short description */}
              </div>
              {/* End author */}

              <div className="icon">
                <img className="svg" src="img/svg/quote.svg" alt="quote" />
              </div>
              {/* End right quote icon */}
            </div>

            <div className="text">
              <p>{val.desc}</p>
            </div>
            {/* End description */}
          </div>
        </li>
      ))}
    </Slider>
  );
}
