import React from "react";

const achievementsContent = [
  { title: "45", subTitle1: "Anni di", subTitle2: "Esperienza" },
  { title: "15", subTitle1: "Consulenze", subTitle2: "Completate" },
  { title: "500", subTitle1: "Clienti", subTitle2: "Soddisfatti" },
  { title: "50", subTitle1: "Attestati ", subTitle2: "Ottenuti" },
];

const Achievements = () => {
  return (
    <div className="row">
      {achievementsContent.map((val, i) => (
        <div className="col-6" key={i}>
          <div className="box-stats with-margin">
            <h3 className="poppins-font position-relative">{val.title}</h3>
            <p className="open-sans-font m-0 position-relative text-uppercase">
              {val.subTitle1} <span className="d-block">{val.subTitle2}</span>
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Achievements;
