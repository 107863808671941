import React from "react";

const ModalFour = () => {
  return (
    <div className="popup_informations">
      <div className="image">
        <img src="img/thumbs/4-3.jpg" alt="" />
        <div
          className="main"
          style={{
            backgroundImage: `url(${
              process.env.PUBLIC_URL + "img/service/4.jpg"
            })`,
          }}
        ></div>
      </div>
      {/* End big image */}

      <div className="description">
        <h3>Studio, elaborazione e stesura di nuovi menu</h3>
        <p>Rivolto a tutto il settore del food service.</p>
        <div className="in_list">
          <ul>
            <li>
              <p>Ristoranti</p>
            </li>
            <li>
              <p>Alberghi</p>
            </li>
            <li>
              <p>Street food</p>
            </li>
            <li>
              <p>Fast food</p>
            </li>
            <li>
              <p>Nuove tendenze del mercato</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ModalFour;
