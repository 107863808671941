import React from "react";

const educationContent = [
  {
    year: "2000",
    degree: "INSIGNITO TITOLO MAESTRO DI CUCINA",
    institute: "FEDERAZIONE ITALIANA CUOCHI",
  },
  {
    year: "2005",
    degree: "DEGUSTATORE OLIO D'OLIVA",
    institute: "O.N.A.O. ",
  },
  {
    year: "2004",
    degree: "DEGUSTATORE UFFICIALE",
    institute: "ASSOCIAZIONE ITALIANA SOMMELIER",
  },
  {
    year: "1996",
    degree: "QUALIFICA SOMMELIER PROFESSIONISTA",
    institute: "ASSOCIAZIONE ITALIANA SOMMELIER",
  },
  {
    year: "1988",
    degree: "ATTESTATO TEORICO & PRATICO DISTILLATORE",
    institute: "DISTILLERIA BONAVENTURA MASCHIO",
  },
  {
    year: "1978",
    degree: "TECNICO ATTIVITA' ALBERGHIERE ",
    institute: "IPAS ROCCARASO AQUILA",
  },
];

const Education = () => {
  const style = {
    width: "50%",
  };
  return (
    <ul>
      {educationContent.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <img style={style} src="img/about/book.png" alt="icon" />
          </div>
          <span className="time open-sans-font text-uppercase">{val.year}</span>
          <h5 className="poppins-font text-uppercase">
            {val.degree}
            <span className="place open-sans-font">{val.institute}</span>
          </h5>
          <p className="open-sans-font">{val.details}</p>
        </li>
      ))}
    </ul>
  );
};

export default Education;
